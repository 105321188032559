import React, { Component } from 'react';
import Layout from '../components/organisms/Layout';
import WorkList from '../components/organisms/WorkList';

class Work extends Component {
  render() {
    return (
      <Layout>
        <article>
          <WorkList data={this.props.data.allMarkdownRemark.edges}/>
        </article>
      </Layout>
    )
  }
}

export default Work;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex : "\/_posts/work/"}
      }
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ){
      edges {
        node{
          id
          frontmatter {
            title
            material
            size
            year
            image
            project
          }
        }
      }
    }
  }
`